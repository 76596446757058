.entry-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50vw;
  margin-top: 24px;
}

.field {
  width: 50%;
  color: white;
  font-size: 16px;
  text-align: center;
}

.available {
  color: #33cc33;
}
