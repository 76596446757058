.attribute-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 24px;
}

.attribute-input {
  width: 24px;
}

.attribute-label {
  color: white;
  font-size: 16px;
  margin-left: 4px;
}
