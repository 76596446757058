#results-container {
  display: flex;
  background-color: #333333;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  width: 100vw;
}

.user-entry {
  color: white;
}
